.DateRangePicker_picker {
   z-index: 10;
}

/*Will edit everything selected including everything between a range of dates*/
.CalendarDay__selected_span {
   background: #44A6EF;
   color: white;
   border: 1px solid #86C4F0;
}

/*Will edit selected date or the endpoints of a range of dates*/
.CalendarDay__selected {
   background: #44A6EF;
   color: white;
}

/*Will edit when hovered over. _span style also has this property*/
.CalendarDay__selected:hover {
   background: #86C4F0;
   color: white;
}

/*Will edit when the second date (end date) in a range of dates*/
/*is not yet selected. Edits the dates between your mouse and said date*/
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
   background: #86C4F0;
}

